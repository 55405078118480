<template>
  <div class="card">
    <form>
      <div class="card-header">
        <h4 class="card-title">Prescription Edit Form</h4>
      </div>
      <div class="card-body" v-if="model">
        <div class="row">
          <div class="form-group col-3">
            <label for>Location</label>
            <fg-input
              name="location_id"
              v-model="model.location_id"
              v-validate="modelValidations.location_id"
              :error="getError('location_id')"
            >
              <el-select
                name="location_id"
                :class="!getError('location_id') ? 'select-default-special' : 'select-danger'"
                placeholder="Select Location"
                v-model="model.location_id"
                filterable
              >
                <el-option
                  v-for="(option, index) in locations"
                  class="select-success"
                  :value="option.id"
                  :label="`${option.location_id} - ${option.name}`"
                  :key="index"
                ></el-option>
              </el-select>
            </fg-input>
          </div>

          <div class="form-group col-3">
            <label for>Patient</label>
            <fg-input
              name="patient_id"
              v-model="model.patient_id"
              v-validate="modelValidations.patient_id"
              :error="getError('patient_id')"
            >
              <el-select
                name="patient_id"
                :class="!getError('patient_id') ? 'select-default-special' : 'select-danger'"
                placeholder="Select Patient"
                v-model="model.patient_id"
                filterable
                @change="updatePatient()"
              >
                <el-option
                  v-for="(option, index) in patients"
                  class="select-success"
                  :value="option.id"
                  :label="`${option.patient_id} - ${option.name} -  ${option.phone}`"
                  :key="index"
                ></el-option>
              </el-select>
            </fg-input>
          </div>

          <!-- <div class="form-group col-3">
            <label for>Patient</label>
            <el-select
              name="type"
              class="select-default"
              placeholder="Select Type"
              v-model="model.patient_id"
              
            >
              <el-option
                v-for="patient in patients"
                class="select-success"
                :value="patient.id"
                :label="`${patient.patient_id} - ${patient.name}`"
                :key="patient.patient_id"
              ></el-option>
            </el-select>
          </div>-->
          <div class="form-group col-3">
            <label>Patient Name</label>
            <fg-input readonly type="text" name="patient_name" v-model="model.patient.name"></fg-input>
          </div>

          <div class="form-group col-1">
            <label>Patient Age</label>
            <fg-input readonly type="text" name="patient_age" v-model="age"></fg-input>
          </div>

          <div class="form-group col-2">
            <label>Patient Contact</label>
            <fg-input readonly type="text" name="patient_phone" v-model="model.patient.phone"></fg-input>
          </div>

          <div class="form-group col-4">
            <label>Diagnosis</label>
            <fg-input
              type="text"
              name="diagnosis"
              v-validate="modelValidations.diagnosis"
              :error="getError('diagnosis')"
              v-model="model.diagnosis"
            ></fg-input>
          </div>

          

          <div class="form-group col-4">
            <label>Prescription Date</label>
            <fg-input
              name="date"
              type="datetime"
              v-validate="modelValidations.date"
              v-model="model.date"
              :error="getError('date')"
            >
              <el-date-picker
                name="date"
                v-model="model.date"
                type="date"
                placeholder="Pick a Date"
              ></el-date-picker>
            </fg-input>
          </div>

          <div class="form-group col-4">
            <label>Notes</label>
            <fg-input
              type="text"
              name="note"
              v-validate="modelValidations.note"
              :error="getError('note')"
              v-model="model.note"
            ></fg-input>
          </div>
        </div>
        <hr>

        <el-table style="width: 100%" :data="model.items" height="300">

          <el-table-column
            type="index">
          </el-table-column>

          <el-table-column min-width="220" label="Product" align="center">
            <div slot-scope="scope" class="input-container">
              <el-select
                name="product_id"
                class="select-default"
                placeholder="Select Product"
                v-model="scope.row.product_id"
                filterable
                @change="updateProduct(scope.$index)"
              >
                <el-option
                  v-for="(option, index) in products"
                  class="select-success"
                  :value="option.id"
                  :label="`${option.product_id} - ${option.name}`"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
          </el-table-column>

          <el-table-column min-width="220" label="Frequency" align="center">
            <div slot-scope="{row}" class="input-container">
              <el-select
                name="frequency"
                class="select-default"
                placeholder="Select Frequency"
                v-model="row.frequency.id"
                filterable
              >
                <el-option
                  v-for="(option, index) in frequencies"
                  class="select-default"
                  :value="option.id"
                  :label="`${option.value}`"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
          </el-table-column>

          <el-table-column min-width="120" label="Dose" align="center">
            <div slot-scope="{row}" class="input-container">
              <el-select
                name="dose_1"
                clearable
                class="select-default"
                placeholder="Select Dose"
                v-model="row.dose_1.id"
                filterable
              >
                <el-option
                  v-for="(option, index) in dosages"
                  class="select-default"
                  :value="option.id"
                  :label="`${option.value} ${row.product.product_type.unit}`"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
          </el-table-column>

          <el-table-column min-width="120" label="Dose" align="center">
            <div slot-scope="{row}" class="input-container">
              <el-select
                name="dose_2"
                clearable
                class="select-default"
                placeholder="Select Dose"
                v-model="row.dose_2.id"
                filterable
              >
                <el-option
                  v-for="(option, index) in dosages"
                  class="select-default"
                  :value="option.id"
                  :label="`${option.value} ${row.product.product_type.unit}`"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
          </el-table-column>

          <el-table-column min-width="120" label="Dose" align="center">
            <div slot-scope="{row}" class="input-container">
              <el-select
                name="dose_3"
                clearable
                class="select-default"
                placeholder="Select Dose"
                v-model="row.dose_3.id"
                filterable
              >
                <el-option
                  v-for="(option, index) in dosages"
                  class="select-default"
                  :value="option.id"
                  :label="`${option.value} ${row.product.product_type.unit}`"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
          </el-table-column>

          <el-table-column min-width="150" label="Comment" align="center">
            <div slot-scope="{row}" class="input-container">
              <el-select
                name="comment"
                class="select-default"
                placeholder="Select Comment"
                v-model="row.comment.id"
                filterable
              >
                <el-option
                  v-for="(option, index) in comments"
                  class="select-default"
                  :value="option.id"
                  :label="`${option.value}`"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
          </el-table-column>

          <el-table-column min-width="250" label="Extra" align="center">
            <div slot-scope="{row}" class="input-container">
              <fg-input
                name="extra"
                type="text"
                v-model="row.extra">
              </fg-input>
            </div>
          </el-table-column>

          <el-table-column min-width="150" label>
            <div slot-scope="props" class="td-actions">
              <p-button type="danger" size="sm" simple icon outline round @click="removePrescriptionItem(props.$index)">
                <i class="fa fa-trash"></i>
              </p-button>
              <p-button type="info" size="sm" simple icon outline round @click="addPrescriptionItem()">
                <i class="fa fa-plus"></i>
              </p-button>
            </div>
          </el-table-column>

        </el-table>

      </div>
      <div class="card-footer text-right">
        <p-button type="info" @click.prevent="validate">Save Prescription</p-button>
      </div>
    </form>
  </div>
</template>
<script>
import { Upload, DatePicker, Select, Option, Table, TableColumn } from "element-ui";
import { mapGetters } from "vuex";
export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Upload.name]: Upload,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  mounted() {
    this.$store.dispatch("fetchPrescription",this.$route.params.prescription_id);
    this.$store.dispatch("fetchSimplePatientsAll");
    this.$store.dispatch("fetchLocationsAll");
    this.$store.dispatch("fetchProductsAll");
    this.$store.dispatch("fetchFrequenciesAll");
    this.$store.dispatch("fetchDosagesAll");
    this.$store.dispatch("fetchCommentsAll");
  },
  computed: {
    ...mapGetters({
      patients: "getPatients",
      locations: "getLocations",
      products: "getProducts",
      items: "getPrescriptionItems",
      frequencies: "getFrequencies",
      dosages: "getDosages",
      comments: "getComments",
      currentUser: "getCurrentUser",
      model: "getPrescription",
    }),
    age: function() {
      const getAge = birthDate =>{
          if(typeof(birthDate) == 'string'){
            birthDate = birthDate.split('-');
            return Math.floor((new Date() - new Date(birthDate[0], birthDate[1], birthDate[2]).getTime()) / 3.15576e10);        
          }
          else{
            return Math.floor((new Date() - birthDate.getTime()) / 3.15576e10);
          }
      }

      if (this.model.patient.dob) return getAge(this.model.patient.dob);
      else return 0;
    }
  },
  data() {
    return {
      types: [
        { value: "tablet", label: "Tablet" },
        { value: "pills", label: "Pills" },
        { value: "syrup", label: "Syrup" }
      ],
      current_patient: null,
      modelValidations: {
        patient_id: {
          required: true
        },
        location_id: {
          required: true
        },
        
        diagnosis: {
          required: true
        },
        note: {
          required: false
        },
        date: {
          required: true
        }
      }
    };
  },
  methods: {
    updatePatient() {
      this.model.patient = this.patients[
        this.patients.findIndex(element => {
          return element.id == this.model.patient_id;
        })
      ];
    },
    updateProduct(index) {

        let product = this.products[
            this.products.findIndex(element => {
                return element.id == this.model.items[index].product_id;
            })
        ];

        if(product){
            this.model.items[index].product = product
            console.log(product)
            // this.model.items[index].frequency = this.frequencies[0].id
            // this.model.items[index].dose_1 = this.dosages[0].id
            // this.model.items[index].dose_2 = this.dosages[0].id
            // this.model.items[index].dose_3 = this.dosages[0].id
            // this.model.items[index].comment = this.comments[0].id
        }

    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then(isValid => {
        this.$emit("on-submit", this.registerForm, isValid);
        if( isValid ){
          this.model.employee_id = this.currentUser.profile_id;
          this.$store.dispatch('updatePrescription', this.model)
        }
      });
    },
    increaseQuantity(row) {
      row.quantity++;
      this.computeAmount(row);
    },
    decreaseQuantity(row) {
      if (row.quantity > 1) {
        row.quantity--;
        this.computeAmount(row);
      }
    },
    computeAmount(row) {
      row.amount = row.quantity * row.price;
    },
    addPrescriptionItem(){
      this.$store.commit('addPrescriptionItem')
    },
    removePrescriptionItem(index){
      this.$store.commit('removePrescriptionItem', index)
    }
  }
};
</script>
<style>
</style>
